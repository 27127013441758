@import '@ctrl/ngx-emoji-mart/picker';

/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: NanumGothic;
  src: url(assets/font/NanumGothic-Regular.ttf) format("opentype");
}

$primary-color: #1d1757;
$primary-color-opacity: #1d175780;
$secondary-color: #5fcae1;
$active-color: #ffb134;
$warning-color: #ffb134;
$default-color: #212529;
$background-color: #f5f5f5;
$secondary-background-color: #f5f5f5;
$text-color: black;
$secondary-text-color: white;
$primary-error-color: #f50000;
$primary-success-color: #83EB1B;
//Social Colours
$facebook-color: #4267b2;
$twitter-color: rgb(29, 161, 242);
$instagram-color: linear-gradient(-135deg,#1400c8,#b900b4,#f50000);

$md-light-blue: #5fcae1;
$md-orange: #fc8800;

$skins: () !default;
$skins: map-merge((
  "MktDel": (
    "skin-primary-color": $primary-color,
    "skin-secondary-color": $secondary-color,
    "skin-navbar": $primary-color,
    "skin-footer-color": $primary-color,
    "skin-accent": $active-color,
    "skin-flat": $primary-color-opacity,
    "skin-sidenav-item": $active-color,
    "skin-sidenav-item-hover": $active-color,
    "skin-gradient-start": purple,
    "skin-gradient-end": violet,
    "skin-mask-slight": cyan,
    "skin-mask-light": $primary-color-opacity,
    "skin-mask-strong": grey,
    "skin-sn-child": $active-color,
    "skin-btn-primary": $primary-color,
    "skin-btn-secondary": $secondary-color,
    "skin-btn-default": $active-color,
    "skin-text": $secondary-text-color
  )
), $skins);

// $theme-colors: (
//   facebook: $primary-color,
//   twitter: $
// ) !default;

@import 'ng-uikit-pro-standard/assets/scss/core/mixins';
@import 'ng-uikit-pro-standard/assets/scss/core/colors';
@import 'ng-uikit-pro-standard/assets/scss/core/variables';
@import 'ng-uikit-pro-standard/assets/scss/core/variables-pro';
@import 'ng-uikit-pro-standard/assets/scss/core/msc/skins-pro';

@import "angular-calendar/css/angular-calendar.css";

@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');

@import "@angular/material/prebuilt-themes/indigo-pink.css";

body {
  font-family: NanumGothic, sans-serif;
  background-color: $background-color;
  overflow-x: hidden;
}

.bg-facebook {
  background-color: $facebook-color;
  color: white;
}

.bg-twitter {
  background-color: $twitter-color;
  color: white;
}

.bg-instagram {
  background-image: $instagram-color;
  color: white;
}

.nav-space {
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  padding-left: 240px;
  transition: .9s;
}

@media only screen and (max-width : 1440px) {
  .nav-space {
    padding-left: 0;
    transition: .9s;
  }
}

.no-nav-space {
  padding: 0 !important;
  margin: 0 !important;
}

.active {
  color: $active-color !important;
}

.btn-primary:hover {
  color: $active-color;
}

mdb-select div div div {
  color: $text-color !important;
  font-family: NanumGothic, sans-serif;
}

mdb-select div div ul span {
  color: $text-color !important;
  font-family: NanumGothic, sans-serif;
}

mdb-select label {
  color: $text-color !important;
  font-family: NanumGothic, sans-serif;
}

input {
  color: $text-color !important;
  font-family: NanumGothic, sans-serif;
}

label {
  color: $text-color !important;
  font-family: NanumGothic, sans-serif;
}

mdb-date-picker div div {
  color: $text-color !important;
  font-family: NanumGothic, sans-serif;
}

.options-icon {
  color: $secondary-color;
}

.options-icon:hover {
  color: $active-color;
  transition: .3s;
}

.card-body {
  padding-top: 0;
  padding-bottom: 0;
  height: auto !important;
}

.picker {
  position: static !important;
}

.picker__holder {
  z-index: 9999;
}

.remove-bottom-margin {
  margin-bottom: 0 !important;
}

.remove-top-margin {
  margin-top: 0 !important;
}

.remove-vertical-margin {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.text-primary {
  color: $primary-color !important;
}

.remove-form-control-border {
  border: none;
  margin: 0;
  padding: 0;
}

.carousel-inner {
  max-height: 100%
}

.icon-color {
  color: $primary-color;
}

mdb-tooltip-container {
  overflow: visible !important;
}

.trigger-container div ul {
  overflow: visible;
  li {
    .step-title {
      color: black !important
    }
    .step-new-content  {
      overflow: visible !important;
    }
  }
}

// Applied to every dropdown in the site by default to prevent spans from becomming bigger vertically.

mdb-select-dropdown div div ul li span {
  white-space: nowrap;
  overflow-x:auto;
  overflow-y:hidden;
/*  min-width: 320px;
*/  line-height: 16px !important;
}

/* ----------------------- Side Bar Styles ----------------------- */

.sn-bg-1 {
  background-color: #1d1757 !important;
  background-image: none !important;
/*    background-image: url('/../../assets/images/navbar-image.jpg') !important;
*/
}

.sidenav .fa-home {
  color: #fc8800;
}

.sidenav .fa-envelope {
  color: #fc8800;
}

.sidenav .fa-cog {
  color: #fc8800;
}

.sidenav .fa-retweet {
  color: #fc8800;
}

.sidenav .fa-newspaper {
  color: #fc8800;
}

.sidenav .fa-address-book {
  color: #fc8800;
}

.sidenav .fa-network-wired {
  color: #fc8800;
}

.sidenav .fa-share-square {
  color: #fc8800;
}

.sidenav .fa-users {
  color: #fc8800;
}

.sidenav .fa-user-astronaut {
  color: #fc8800;
}

.sidenav .fa-handshake {
  color: #fc8800;
}

.sidenav .fa-power-off {
  color: #fc8800;
}

.sidenav .fa-images {
  color: #fc8800;
}

.sidenav .fa-toolbox {
  color: #fc8800;
}

.sidenav .fa-binoculars {
  color: #fc8800;
}

.dropup .dropdown-toggle:empty::after
{
    color:white;
}

.client-dropdown div div div {
  color: $active-color !important;
}

.client-dropdown div div span {
  color: $active-color !important;
}

.client-dropdown div div ul span {
  color: $text-color !important;
}

/*sub-menu item*/
.MktDel-skin .side-nav .collapsible li a:not(.collapsible-header):hover,
.MktDel-skin .side-nav .collapsible li a:not(.collapsible-header).active,
.MktDel-skin .side-nav .collapsible li a:not(.collapsible-header):active {
  color: white !important;
  background-color: #242865 !important;
}

/*focus menu item*/
.MktDel-skin .side-nav .collapsible .card .card-header a:hover {
  background-color: #242865;
}

/*active menu item*/
.card-header .active {
  background-color: #242865;
  padding-left: 19px !important;
  margin-left: -19px !important;
}

/*active text menu item*/
a.active {
  color: white !important;
}

.MktDel-skin .dropdown-menu a:hover,
.MktDel-skin .dropdown-menu a:focus,
.MktDel-skin .dropdown-menu a:active {
  background-color: #242865 !important;
  color: white;
}

.flex-voice-box-container {
  display: flex;
  flex-direction: column;
}

.flex-voice-box-container > div {
  text-align: center;
  line-height: 40px;
}

.flex-client-container {
  display: flex;
  flex-direction: column;
  background-color: #1d1757;
}

.image-container {
  background-color: white;
}

.hr-after-logo {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 16px;
  margin-right: 16px;
  background-color: #d9d9d9;
  opacity: 0.3;
}

.line {
  background-color: white;
}

/* ----------------------- End Of Side Bar Styles ----------------------- */

/* ----------------------- Social Media Styles --------------------- */

.posts-image-modal div figure {
  padding: 0 !important;
  margin: 3px !important;
  max-height: 100px;
  max-width: 100px;
  display: block;
}

.facebook-media-tabset div div div ul {
  background-color: $facebook-color;
}

.twitter-media-tabset div div div ul {
  background-color: $twitter-color;
}

.min-height-150 {
  min-height: 150px !important;
}

.pointer {
  cursor: pointer !important;
}

.scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #1d1757;
}

#DateFromPicker input {
  display: none;
}

#DateToPicker input {
  display: none;
}

#timeFromPicker input {
  display: none;
}

#timeToPicker input {
  display: none;
}

.calendar-post-text {
  background: $background-color;
}

span.cal-event {
  display: none !important  ;
}

.cal-event-title {
  color: white;
}

.modal-overflow {
  overflow-y: auto;
}

.tooltip-inner {
  background-color:$secondary-color !important;
  color: white;
}
// tooltip placement top
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  border-top-color: $secondary-color !important;
}
// tooltip placement bottom
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  border-bottom-color: $secondary-color !important;
}
// tooltip placement right
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  border-right-color: $secondary-color !important;
}
// tooltip placement left
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  border-left-color: $secondary-color !important;
}

/* ----------------------- End Of Social Media Styles --------------------- */

$ngx-datatable-selected-active-background: $secondary-color;
$ngx-datatable-selected-active-color: #fff;
$ngx-datatable-selected-active-background-hover: $secondary-color;
$ngx-datatable-selected-active-color-hover: #fff;
$ngx-datatable-selected-active-background-focus: $secondary-color;
$ngx-datatable-selected-active-color-focus: #fff;

/* ----------------------- Report Styles Start ----------------------------*/

@import '@swimlane/ngx-datatable/index.scss';
@import '@swimlane/ngx-datatable/themes/material.scss';
@import '@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '@swimlane/ngx-datatable/assets/icons.css';


.font-size-12 {
  font-size: 12px;
}

.ngx-datatable.bootstrap .datatable-footer {
  background-color: #1d1757;
}

/* ----------------------- Report Styles End ------------------------------*/

/* ----------------------- Fix for browser autofill colour change Styles End ------------------------------*/

@-webkit-keyframes autofill {
  to {
  color: #666;
  background: transparent; } }

  @keyframes autofill {
  to {
  color: #666;
  background: transparent; } }

  input:-webkit-autofill {
  -webkit-animation-name: autofill;
  animation-name: autofill;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

  .md-form > input[type]:-webkit-autofill:not(.browser-default):not([type='search']) + label {
    transform: translateY(-14px);
  }

/* ----------------------- Fix for browser autofill colour change Styles End ------------------------------*/

/* ----------------------- Lits and Contacts - Lists Start ------------------------*/

/* datatable-body-row .datatable-row-right {
  position: relative;
  // left: 17px;
  transform: none !important;
} */

.lists-table-right-cells {
  padding: 0 !important;
  overflow: visible !important;
}

.lists-table-headers {
  font-family: NanumGothic, sans-serif !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

datatable-body-row {
  user-select: none;
}

datatable-header {
  user-select: none;
}

.disabled {
  color: grey !important;
}

.list-web-forms-icon {
  color: $secondary-color;
}

.list-web-forms-icon:hover {
  color: $active-color;
  transition: .3s;
}

.list-edit-icon {
  color: $secondary-color;
}

.list-edit-icon:hover {
  color: $active-color;
  transition: .3s;
}

.list-contacts-icon {
  color: $secondary-color;
}

.list-contacts-icon:hover {
  color: $active-color;
  transition: .3s;
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

/* ----------------------- Lits and Contacts - Lists End ------------------------*/

/* ----------------------- Lits and Contacts Forms - Forms Start ------------------------*/

.form-tabset div div div ul {
  background-color: $primary-color;
  padding: 0;
  border-radius: .25rem;
}

/* ----------------------- Lits and Contacts Forms - Forms End ------------------------*/

/* ----------------------- Messages - Reports Start ------------------------*/

.report-details-icon {
  color: $secondary-color;
}

.report-details-icon:hover {
  color: $active-color;
  transition: .3s;
}

.link-stats mdb-progress div mdb-bar div {
  height: 100% !important;
}

.link-stats mdb-progress div mdb-bar div:hover {
  height: 100% !important;
  background-color: $active-color !important;
  transition: .5s;
}

.bottom-progress div {
  margin: 0 !important;
}

/* ----------------------- Messages - Reports End ------------------------*/

/* ----------------------- Vehicle Validator (MOT Box) Tool - Start ------------------------*/

.vehicleValidator-progressbar .progress-bar, .progress {
  height: 20px !important;
}

/* ----------------------- Vehicle Validator (MOT Box) Tool - End ------------------------*/

/* ----------------------- Preloader Script - Start ------------------------*/

.spinning-preloader-container {
  z-index: 10000;
  display: flex !important;
}

.spinning-preloader-container.complete {
  // Sends it to the very back so it doesnt overlay anything, this is to allow for the fade animation to work;
  z-index: -999999999 !important;
}

/* ----------------------- Preloader Script - END ------------------------*/

/* ----------------------- Query Builder - Start ------------------------*/

.rules-group-header .pull-right {
  float: right !important;
}

.rule-header .pull-right {
  float: right !important;
}

#builder_group_0 {
  min-width: 100% !important;
}

.rule-actions button {
  padding: 10px;
}

// .builder .rules-group-header .group-conditions .active {
//     background-color: White !important;
//     color: white !important;
//   }

.rule-value-container {

  label input {
    position: relative !important;
    opacity: 1 !important;
    margin-right: .5rem;
  }

  .radio {
    input {
      opacity: 100;
      pointer-events: all !important;
      margin: 5px !important;
    }
    label {
      margin-left: 25px;
    }
  }

  .selectize-dropdown {
    height: auto !important;
  }

  .selectize-input input {
    width: 100% !important;

  }

  .selectize-control {
    min-width: 250px !important;
    padding: 0 !important;

    .selectize-input {
      width: 100% !important;
    }
  }
}
/* ----------------------- Query Builder - End ------------------------*/


/* ----------------------- Message Page - Start ------------------------*/

.datatable-row-right {
  margin-left: 1em;
}
/* ----------------------- Message Page - End ------------------------*/


/* ----------------------- Generic Table Styles -------------------- */

.table-custom-footer{
  position:absolute !important;
  right:130px !important;
}

.table-page-limit-select{
  position:absolute !important;
  left:72px !important;
  top:1px !important;
  font-size:12px;
  color:rgba(0, 0, 0, 0.54);
  font-family: NanumGothic, sans-serif;
}

.table-custom-pager {
  position: absolute !important;
  top: -7px;
  left: -725px;
}

/* ----------------------- Start Automation Toggle All Loading Bar ------------------------------*/

.loading-bar-container {
  mdb-progress {
    div mdb-bar div {
      height: 100% !important;
    }
    .progress .progress-bar {
      background-color: $secondary-color !important;
    }
  }
}
/* ----------------------- End Automation Toggle All Loading Bar ------------------------------*/

/* -----------------------------Start Query-builder styles ----------------------------------------*/
.pull-right {
  float: right !important;
}
.query-builder, .query-builder *, .query-builder .btn {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.query-builder {
  font-family: sans-serif;
}

.query-builder .hide {
  display: none;
}

.query-builder .pull-right {
  float: right !important;
}


.query-builder  .btn {
  font-family: NanumGothic, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  text-transform: none;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid;
  border-radius: 4px;
  box-shadow: none !important;
}

.query-builder .btn.focus, .query-builder .btn:focus, .query-builder .btn:hover {
  color: #333 !important;;
  text-decoration: none;
}

.query-builder button.btn.btn-xs.btn-default.active {
  border-color: #1d1757 !important;
  background-color: #1d1757 !important;
  color: white;
}

.query-builder button.btn.btn-xs.btn-default {
  border-color: #1d1757 !important;;
  color: #1d1757;
  text-transform:initial;
}



.query-builder .btn.btn-xs.active, .query-builder .btn.btn-xs:active {
  background-color: #1d1757 !important;
  color: white !important;;
}

.query-builder .btn-success {
  color: #FFF !important;;

  background-color: #0dc110 !important;
  border-color: #4CAE4C !important;
}

.query-builder .btn.btn-xs.btn-primary {
  color: #1d1757 !important;
  background-color: transparent !important;;
  border-color: #1d1757 !important;;
}

.query-builder .btn-danger {
  color: #FFF;
  background-color: #fc4569 !important;;
  border-color: #fc4569 !important;;
  border-radius: 4px !important;
}

.query-builder .btn-success.active, .query-builder .btn-success.focus,
.query-builder .btn-success:active, .query-builder .btn-success:focus,
.query-builder .btn-success:hover {
  color: #FFF !important;;
  background-color: #449D44 !important;;
  border-color: #398439 !important;;
}

.query-builder .btn-xs.btn-primary.active, .query-builder .btn-xs.btn-primary.focus,
.query-builder .btn-xs.btn-primary:active, .query-builder .btn-xs.btn-primary:focus,
.query-builder .btn-xs.btn-primary:hover {
  color: #FFF !important;;
  background-color: #286090 !important;;
  border-color: #204D74 !important;;
}

.query-builder .btn-danger.active, .query-builder .btn-danger.focus,
.query-builder .btn-danger:active, .query-builder .btn-danger:focus,
.query-builder .btn-danger:hover {
  color: #FFF !important;;
  background-color: #C9302C !important;;
  border-color: #AC2925 !important;;
}

.query-builder .btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.query-builder .btn-group > .btn {
  position: relative;
  float: left;
}

.query-builder .btn-group > .btn:first-child {
  margin-left: 0;
}


.query-builder .btn-group .btn + .btn, .query-builder .btn-group .btn + .btn-group,
.query-builder .btn-group .btn-group + .btn, .query-builder .btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.query-builder .btn-xs, .query-builder .btn-group-xs > .btn {
  font-size: 12px;
  line-height: 1.5;
  border-radius: 4px;
}

.query-builder .rules-group-container {
  padding: 10px 10px 6px;
  border: 1px solid #d9d9d9;
  background: white !important;;
}

.query-builder .rule-filter-container .form-control {
  width: 100%;
}

.query-builder .rule-filter-container {
  order: 1;
  flex: 1 1 auto;
  width: calc(100% - 200px);
}

.query-builder .rule-header {
  order: 2;
  margin-left: auto;
  margin-right: 5px;
}

.query-builder .rule-value-container {
  order: 4;
  flex-basis: 46%;
}

.query-builder .rule-operator-container {
  order: 3;
  flex-basis: 45%;
}

.query-builder .rule-container {
  align-items: center;
  display: flex !important;
  flex-wrap: wrap;
}

.query-builder .btn-default {
  border-color: #1d1757 !important;;
  text-transform: initial;
}

.query-builder .query-builder .rule-container .rule-value-container {
  margin-right: 10px;
  display: inline-flex;
}

.query-builder .rule-value-container label {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  font-family: NanumGothic, sans-serif;
  display: inline-flex;
}

.query-builder .rules-group-header [data-invert] {
  background: #fc8800 !important;;
  border: none !important;;
  color: white !important;
}

.query-builder .rules-group-header .btn:active [data-invert] {
  background: #fc8800 !important;
}


.query-builder button.btn.btn-xs.btn-success {
  float: none !important;;
  text-transform: initial;
}

.query-builder .rule-value-container input[type="checkbox"] {
  margin: 5px;
}

.query-builder .rule-value-container input[type="radio"] {
  margin: 5px;
}

.query-builder .rule-container .rule-filter-container, .query-builder .rule-container .rule-operator-container, .query-builder .rule-container .rule-value-container, .query-builder .error-container, .query-builder .drag-handle {
  margin: 5px;
}

.query-builder .rules-group-container {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  border: none;
}

.query-builder button.btn.btn-xs.btn-danger {
  float: none;
  text-transform: initial;
}

.query-builder .btn-xs.btn-primary:not(:disabled):not(.disabled):active,
.query-builder .btn-xs.btn-primary:not(:disabled):not(.disabled).active,
.query-builder .show > .btn-xs.btn-primary.dropdown-toggle
{
  color: #fff !important;
}

div.rules-group-header > div.btn-group.group-conditions > button[data-not='group']{
  background-color: transparent !important;
}

.query-builder .btn-xs.btn-primary:not(:disabled):not(.disabled):active,
.query-builder .btn-xs.btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-xs.btn-primary.dropdown-toggle {
  background-color: #1d1757 !important ;
  border-color: #1d1757 ;
}

.query-builder .rules-list > *:last-child::before {
  border-radius: 0;
  margin-top: 1px;
}

.query-builder .rules-list > *::before {
  border-width: 0 0 1px 1px;
}

.query-builder .rules-list > ::before {
  border-left-width: 1px;
}

.query-builder .rules-list > ::after {
  border-left-width: 1px;
}

.query-builder button.btn.btn-xs {
  text-transform: uppercase;
}

.query-builder .rules-group-container, .query-builder .rule-container, .query-builder .rule-placeholder {
  border: 1px solid #d9d9d9 !important;;
}

.query-builder button.btn.btn-xs.btn-success {
  border-radius: 4px !important;
}


.query-builder .rule-value-container select, .query-builder .rule-value-container input[type='text'], .query-builder .rule-value-container input[type='number'] {
  padding: 6px 8px;
}

.query-builder div.rule-value-container > input[type='text']:focus{
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

@media (min-width: 576px) {
  .query-builder label {
    justify-content: unset;
  }
  .query-builder .form-control {
    width: 100%;
  }
}

.query-builder .rule-filter-container select.form-control,
 .query-builder .rule-operator-container select.form-control{
  padding: 6px 8px;
  text-transform: capitalize;
  font-family: NanumGothic, sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000 !important;;
}

.query-builder .rule-value-container select.form-control{
  padding: 6px 8px;
  text-transform: capitalize;
  font-family: NanumGothic, sans-serif !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000 !important;;
}

.query-builder input.form-control {
  height: 32px;
  font-family: NanumGothic, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  padding: 6px 8px;
}

.query-builder button.btn.btn-xs {
  height: 32px;
}

.query-builder .fa-arrows-alt:before {
  font-size: 12px;
}

.query-builder .rule-filter-container select, .query-builder .rule-operator-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.query-builder label.btn.btn-xs.btn-primary.active.disabled {
  color: #1d1757 !important;
}

.query-builder .rule-value-container {
  padding-left: 10px;
}

.query-builder .group-conditions .btn {
  height: auto !important;
}

.query-builder input[type=number].form-control {
  -moz-appearance: textfield;
}

.query-builder input::-webkit-outer-spin-button,
.query-builder input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.query-builder .rules-list > *:first-child::before {
  top: -11px;
}

.query-builder textarea#previewSqlValue:disabled {
  pointer-events: all !important;
}
/* -----------------------------Start Query-builder styles ----------------------------------------*/

/* ----------------------- Start Modal window ------------------------------*/
.modal-title.w-100{
  font-family: NanumGothic, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  padding-left: 8px;
}

.modal-header{
  height: 100%;
}

.consent-history-page,
.contact-interactions-page,
.contacts-page,
.lists-page,
.reports-page,
.message-page,
.network-management-page,
.network-group-management-page,
.create-edit-post-page,
.single-network-report,
.email-sms-report-details,
.post-page {
  padding-top: 32px;
  padding-left: 25px;
  padding-right: 25px;
}

.post-sub-page {
  padding-left: 25px;
  padding-right: 25px;
}

.title-box {
  display: flex;
  justify-content: space-between;
}

.header-section {
  width: 800px;
  font-family: NanumGothic, sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.63;
  letter-spacing: normal;
  color: #1d1757;
  padding-left: 24px;
}

.contacts-option-section,
.lists-option-section,
.report-option-section,
.message-option-section,
.message-send-section,
.report-selection,
.post-section {
  border-radius: 5px;
  box-shadow: 2px 2px 6px 0 rgb(0 0 0 / 30%);
  background-color: #ffffff;
  padding: 16px 16px;
  margin-top: 24px;
}

.post-sub-page .post-section {
  text-align: left;
}

.input-label {
  font-family: NanumGothic, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  margin-left: 8px;
  margin-bottom: 0;
}

.input-style {
  height: 32px;
  padding: 6px 60px 6px 8px;
  border-radius: 2px;
  border: solid 1px #d9d9d9;
  background-color: #ffffff;
  padding-right: 10px;
  font-size: 14px;
  color: #000000;
  font-family: NanumGothic, sans-serif;
}

.textarea-style {
  padding: 6px 60px 6px 8px;
  border-radius: 2px;
  border: solid 1px #d9d9d9;
  background-color: #ffffff;
  padding-right: 10px;
  font-size: 14px;
  color: #000000;
  font-family: NanumGothic, sans-serif;
  margin: 0;
  height: 160px;
}

.input-style:focus:not([readonly]), textarea.md-textarea.textarea-style:focus:not([readonly]) {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
  border-color: #80bdff !important;
  z-index: 0;
}

.select-input {
  text-transform: none !important;
  height: 32px;
  padding: 6px 8px;
  border-radius: 2px;
  border: solid 1px #d9d9d9;
  background-color: #ffffff;
  font-family: NanumGothic, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.43;
}

.option-text {
  font-weight: normal;
  color: #9c9c9c;
  font-family: NanumGothic, sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 2;
  font-stretch: normal;
  letter-spacing: normal;
}

.option-text-label {
  display: inline-flex !important;
}

.consent-history-table-section,
.contact-interactions-table-section,
.audit-trail-table-section,
.contacts-table-section,
.lists-table-section,
.report-table-section,
.messages-table-section {
  border-radius: 5px;
  box-shadow: 2px 2px 6px 0 rgb(0 0 0 / 30%);
  background-color: #ffffff;
  padding-bottom: 9px;
  padding: 16px 16px;
  padding-right: 24px;
  margin-top: 24px;
  padding-left: 24px;
}

.title-table-section {
  font-family: NanumGothic, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.btn-cancel {
  width: 128px;
  height: 32px;
  font-family: NanumGothic, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  border-radius: 6px;
  border: solid 1px #000000;
  background-color: white;
}

.btn-okay {
  width: 128px;
  height: 32px;
  font-family: NanumGothic, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
  background-color: #5fcae1;
  border-style: none;
}

/* ----------------------- End Modal window ------------------------------*/

/* ----------------------------Start Shared styles for add/edit entity pages ----------------------------*/
.image-back {
  margin-right: 8px;
  cursor: pointer;
}

.label-back {
  font-family: NanumGothic, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000;
  margin-top: 1px;
}

.title-section {
  width: 800px;
  font-family: NanumGothic, sans-serif ;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.63;
  letter-spacing: normal;
  color: #1d1757;
  padding-top: 10px;
  padding-left: 24px;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
}

.navigation {
  margin-left: 24px;
  margin-bottom: 24px;
  display: flex;
}
/* ----------------------------End Shared styles for add/edit entity pages ----------------------------*/

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #fc8800 !important;
}

.mat-table-container .mat-sort-header-content {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
  font-family: NanumGothic, sans-serif;
}

.mat-table-container td,
.mat-table-container th {
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-family: NanumGothic, sans-serif;
}

.mat-table-container td {
  color: rgba(0,0,0,.87);
}

.mat-table-container th {
  white-space: nowrap;
  color: #000000;
}

.mat-table-container .mat-column-checkBox {
  padding-top: 6px;
}

.mat-paginator-container {
  justify-content: space-between !important;
  font-family: NanumGothic, sans-serif;
}


.mat-paginator-icon {
  width: 22px !important;
}

.mat-paginator-range-label {
  padding-top: 5px;
}

.dropdown-item.disabled {
  color: grey !important;
}

.copy-move-modal .mdb-accordion-indicator {
  color: #1d1757;
}

.copy-move-modal .fa-plus-square,
.search-replace-modal .fa-plus-square {
  color: #5fcae1;
}

.confirm-modal .modal-dialog .modal-body input:focus:not([readonly]),
.find-replace-modal .modal-dialog .modal-body input:focus:not([readonly]),
.copy-move-modal .modal-dialog .modal-body input:focus:not([readonly]) {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
  border-color: #80bdff !important;
}

.switch label .lever:after {
  border-radius: 5px;
  background-color: #1d1757;
  width: 18px;
  height: 18px;
}

.switch label input[type=checkbox]:checked + .lever:after {
  background-color: #1d1757;
  left: 1.6rem;
}

.switch-title {
  font-family: NanumGothic, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}



.switch label input[type=checkbox]:checked + .lever {
  background-color: rgba(29, 23, 87, 0.1);
}

.switch label .lever {
  border-radius: 5px;
  box-shadow: 2px 2px 6px 0 rgb(0 0 0 / 30%);
  background-color: rgba(29, 23, 87, 0.1);
  border: solid 1px #1d1757;
  height: 24px;
  width: 48px;
}

.switch label .lever:after {
  top: 2px;
  left: +0.2rem;
  box-shadow: none;
}

.switch label input[type=checkbox]:checked + .lever {
  border: solid 1px #1d1757;
}

.lever {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.switch label .lever {
  box-shadow: none;
}

.message-page .table-button,
.messages-table-section .table-button,
.message-send-page .table-button {
  background-color: rgba(29, 23, 87, 0.1) !important;
  border: solid 1px #1d1757 !important;
  box-shadow: none !important;
}

.table-icon {
  color: #1d1757;
  }

.search-box button:focus {
  outline: none;
}


.mat-checkbox-frame {
  border-radius: 2px;
  border: solid 1px #d9d9d9;
}


.mat-checkbox-checkmark-path {
  stroke: #fc8800 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
  background-color: white !important;
  border-radius: 2px;
  border: solid 1px #fc8800;
}

.mat-checkbox-mixedmark {
  background-color: #fc8800;
}

#selectedNetworkDetailIds .multiple.form-control,
#selectedNetworkIds .multiple.form-control,
#selectedNetworkGroupIds .multiple.form-control {
  font-size: 14px;
  padding-top: 0;
  border: 0;
}

#selectedNetworkDetailIds mdb-select-toggle,
#selectedNetworkIds mdb-select-toggle,
#selectedNetworkGroupIds mdb-select-toggle {
  margin-top: -12px;
}

#selectedNetworkDetailIds span,
#selectedNetworkIds span,
#selectedNetworkGroupIds span {
  font-family: NanumGothic, sans-serif !important;
}

mdb-select .md-form input[type=text]:focus:not([readonly]),
.md-form input[type=password]:focus:not([readonly]),
.md-form input[type=email]:focus:not([readonly]),
.md-form input[type=url]:focus:not([readonly]),
.md-form input[type=time]:focus:not([readonly]),
.md-form input[type=date]:focus:not([readonly]),
.md-form input[type=datetime-local]:focus:not([readonly]),
.md-form input[type=tel]:focus:not([readonly]),
.md-form input[type=number]:focus:not([readonly]),
.md-form input[type=search-md]:focus:not([readonly]),
.md-form input[type=search]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
  box-shadow: 0 1px 0 0 #1d1757;
  border-bottom: 1px solid #1d1757;
}

.form-check-input[type=checkbox]:checked + label:before,
label.btn input[type=checkbox]:checked + label:before {
  border-color: transparent #1d1757 #1d1757 transparent !important;
}

.post-page mdb-select .mdb-select-toggle {
  padding-bottom: 8px !important;
}

.post-sub-page .cal-month-view .cal-day-badge {
  background-color: #9c9c9c;
}

.post-sub-page .cal-month-view .cal-header .cal-cell {
  font-family: NanumGothic, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.post-sub-page .cal-month-view .cal-day-number {
  font-family: NanumGothic, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  margin-top: 18px;
}

.post-sub-page .cal-month-view .cal-open-day-events {
  background-color: #d9d9d9;
  box-shadow: none;
}

.post-sub-page  .cal-month-view
.cal-day-cell.cal-weekend .cal-day-number {
  color: black
}

.post-sub-page .cal-month-view .cal-day-number {
    opacity:inherit
}

//Used for overriding the z index when having modals on top other models
.cdk-global-overlay-wrapper, .cdk-overlay-container { 
  z-index: 9999!important;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 99999 !important;
}

.cct {
  color:green;
  font-weight: bold;
  text-decoration: underline;
  font-style: italic;
}

.modal-close-cross {
  position: relative !important;
  right: 0 !important;
}

.title-sub-section {
  width: 400px;
  height: 40px;
  font-family: NanumGothic, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

#btnCloseModalUnlayer,
#btnSaveMessageUnlayer,
#btnSaveAndExitBuilderUnlayer {
  height: 40px;
}

#btnCloseModalMosaico,
#btnSaveMessageMosaico,
#btnSaveAndExitBuilderMosaico {
  height: 40px;
}

.cdk-global-overlay-wrapper { 
  overflow-y: auto;
  pointer-events: auto;
}

.animationContainer .form-tabset .md-tabs li.active {
  background-color: #16aceb !important;
}

.animationContainer .form-tabset .md-tabs .nav-item.open .nav-link, .md-tabs .nav-link.active {
  background-color: #16aceb !important;
}

#sftp-cred .dropdown-content {
  top: 14px !important;
  overflow-y: auto !important;
  max-height: 200px !important;
}
